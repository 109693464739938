/*
    DJANGO Admin styles
*/

@import url(../admin/css/fonts.css);

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;
    color: #333;
    background: #fff;
}

/* LINKS */

a:link, a:visited {
    color: #447e9b;
    text-decoration: none;
}

a:focus, a:hover {
    color: #036;
}

a:focus {
    text-decoration: underline;
}

a img {
    border: none;
}

a.section:link, a.section:visited {
    color: #fff;
    text-decoration: none;
}

a.section:focus, a.section:hover {
    text-decoration: underline;
}

/* GLOBAL DEFAULTS */

p, ol, ul, dl {
    margin: .2em 0 .8em 0;
}

p {
    padding: 0;
    line-height: 140%;
}

h1,h2,h3,h4,h5 {
    font-weight: bold;
}

h1 {
    margin: 0 0 20px;
    font-weight: 300;
    font-size: 20px;
    color: #666;
}

h2 {
    font-size: 16px;
    margin: 1em 0 .5em 0;
}

h2.subhead {
    font-weight: normal;
    margin-top: 0;
}

h3 {
    font-size: 14px;
    margin: .8em 0 .3em 0;
    color: #666;
    font-weight: bold;
}

h4 {
    font-size: 12px;
    margin: 1em 0 .8em 0;
    padding-bottom: 3px;
}

h5 {
    font-size: 10px;
    margin: 1.5em 0 .5em 0;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 1px;
}

ul li {
    list-style-type: square;
    padding: 1px 0;
}

li ul {
    margin-bottom: 0;
}

li, dt, dd {
    font-size: 13px;
    line-height: 20px;
}

dt {
    font-weight: bold;
    margin-top: 4px;
}

dd {
    margin-left: 0;
}

form {
    margin: 0;
    padding: 0;
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
    border-top: 1px solid #eee;
}

blockquote {
    font-size: 11px;
    color: #777;
    margin-left: 2px;
    padding-left: 10px;
    border-left: 5px solid #ddd;
}

code, pre {
    font-family: "Bitstream Vera Sans Mono", Monaco, "Courier New", Courier, monospace;
    color: #666;
    font-size: 12px;
}

pre.literal-block {
    margin: 10px;
    background: #eee;
    padding: 6px 8px;
}

code strong {
    color: #930;
}

hr {
    clear: both;
    color: #eee;
    background-color: #eee;
    height: 1px;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 1px;
    line-height: 1px;
}

/* TEXT STYLES & MODIFIERS */

.small {
    font-size: 11px;
}

.tiny {
    font-size: 10px;
}

p.tiny {
    margin-top: -2px;
}

.mini {
    font-size: 10px;
}

p.mini {
    margin-top: -3px;
}

.help, p.help, form p.help {
    font-size: 11px;
    color: #999;
}

.help-tooltip {
    cursor: help;
}

p img, h1 img, h2 img, h3 img, h4 img, td img {
    vertical-align: middle;
}

.quiet, a.quiet:link, a.quiet:visited {
    color: #999;
    font-weight: normal;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.clear {
    clear: both;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.example {
    margin: 10px 0;
    padding: 5px 10px;
    background: #efefef;
}

.nowrap {
    white-space: nowrap;
}

/* TABLES */

table {
    border-collapse: collapse;
    border-color: #ccc;
}

td, th {
    font-size: 13px;
    line-height: 16px;
    border-bottom: 1px solid #eee;
    vertical-align: top;
    padding: 8px;
    font-family: "Roboto", "Lucida Grande", Verdana, Arial, sans-serif;
}

th {
    font-weight: 600;
    text-align: left;
}

thead th,
tfoot td {
    color: #666;
    padding: 5px 10px;
    font-size: 11px;
    background: #fff;
    border: none;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

tfoot td {
    border-bottom: none;
    border-top: 1px solid #eee;
}

tr.alt {
    background: #f6f6f6;
}

.row1 {
    background: #fff;
}

.row2 {
    background: #f9f9f9;
}

/* SORTABLE TABLES */

thead th {
    padding: 5px 10px;
    line-height: normal;
    text-transform: uppercase;
    background: #f6f6f6;
}

thead th a:link, thead th a:visited {
    color: #666;
}

thead th.sorted {
    background: #eee;
}

thead th.sorted .text {
    padding-right: 42px;
}

table thead th .text span {
    padding: 8px 10px;
    display: block;
}

table thead th .text a {
    display: block;
    cursor: pointer;
    padding: 8px 10px;
}

table thead th .text a:focus, table thead th .text a:hover {
    background: #eee;
}

thead th.sorted a.sortremove {
    visibility: hidden;
}

table thead th.sorted:hover a.sortremove {
    visibility: visible;
}

table thead th.sorted .sortoptions {
    display: block;
    padding: 9px 5px 0 5px;
    float: right;
    text-align: right;
}

table thead th.sorted .sortpriority {
    font-size: .8em;
    min-width: 12px;
    text-align: center;
    vertical-align: 3px;
    margin-left: 2px;
    margin-right: 2px;
}

table thead th.sorted .sortoptions a {
    position: relative;
    width: 14px;
    height: 14px;
    display: inline-block;
    background: url(../admin/img/sorting-icons.svg) 0 0 no-repeat;
    background-size: 14px auto;
}

table thead th.sorted .sortoptions a.sortremove {
    background-position: 0 0;
}

table thead th.sorted .sortoptions a.sortremove:after {
    content: '\\';
    position: absolute;
    top: -6px;
    left: 3px;
    font-weight: 200;
    font-size: 18px;
    color: #999;
}

table thead th.sorted .sortoptions a.sortremove:focus:after,
table thead th.sorted .sortoptions a.sortremove:hover:after {
    color: #447e9b;
}

table thead th.sorted .sortoptions a.sortremove:focus,
table thead th.sorted .sortoptions a.sortremove:hover {
    background-position: 0 -14px;
}

table thead th.sorted .sortoptions a.ascending {
    background-position: 0 -28px;
}

table thead th.sorted .sortoptions a.ascending:focus,
table thead th.sorted .sortoptions a.ascending:hover {
    background-position: 0 -42px;
}

table thead th.sorted .sortoptions a.descending {
    top: 1px;
    background-position: 0 -56px;
}

table thead th.sorted .sortoptions a.descending:focus,
table thead th.sorted .sortoptions a.descending:hover {
    background-position: 0 -70px;
}

/* FORM DEFAULTS */

input, textarea, select, .form-row p, form .button {
    margin: 2px 0;
    padding: 2px 3px;
    vertical-align: middle;
    font-family: "Roboto", "Lucida Grande", Verdana, Arial, sans-serif;
    font-weight: normal;
    font-size: 13px;
}

textarea {
    vertical-align: top;
}

input[type=text], input[type=password], input[type=email], input[type=url],
input[type=number], textarea, select, .vTextField {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 6px;
    margin-top: 0;
}

input[type=text]:focus, input[type=password]:focus, input[type=email]:focus,
input[type=url]:focus, input[type=number]:focus, textarea:focus, select:focus,
.vTextField:focus {
    border-color: #999;
}

select {
    height: 30px;
}

select[multiple] {
    min-height: 150px;
}

/* FORM BUTTONS */

.button, input[type=submit], input[type=button], .submit-row input, a.button {
    background: #79aec8;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

a.button {
    padding: 4px 5px;
}

.button:active, input[type=submit]:active, input[type=button]:active,
.button:focus, input[type=submit]:focus, input[type=button]:focus,
.button:hover, input[type=submit]:hover, input[type=button]:hover {
    background: #609ab6;
}

.button[disabled], input[type=submit][disabled], input[type=button][disabled] {
    opacity: 0.4;
}

.button.default, input[type=submit].default, .submit-row input.default {
    float: right;
    border: none;
    font-weight: 400;
    background: #417690;
}

.button.default:active, input[type=submit].default:active,
.button.default:focus, input[type=submit].default:focus,
.button.default:hover, input[type=submit].default:hover {
    background: #205067;
}

.button[disabled].default,
input[type=submit][disabled].default,
input[type=button][disabled].default {
    opacity: 0.4;
}


/* MODULES */

.module {
    border: none;
    margin-bottom: 30px;
    background: #fff;
}

.module p, .module ul, .module h3, .module h4, .module dl, .module pre {
    padding-left: 10px;
    padding-right: 10px;
}

.module blockquote {
    margin-left: 12px;
}

.module ul, .module ol {
    margin-left: 1.5em;
}

.module h3 {
    margin-top: .6em;
}

.module h2, .module caption, .inline-group h2 {
    margin: 0;
    padding: 8px;
    font-weight: 400;
    font-size: 13px;
    text-align: left;
    background: #79aec8;
    color: #fff;
}

.module caption,
.inline-group h2 {
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.module table {
    border-collapse: collapse;
}

/* MESSAGES & ERRORS */

ul.messagelist {
    padding: 0;
    margin: 0;
}

ul.messagelist li {
    display: block;
    font-weight: 400;
    font-size: 13px;
    padding: 10px 10px 10px 65px;
    margin: 0 0 10px 0;
    background: #dfd url(../admin/img/icon-yes.svg) 40px 12px no-repeat;
    background-size: 16px auto;
    color: #333;
}

ul.messagelist li.warning {
    background: #ffc url(../admin/img/icon-alert.svg) 40px 14px no-repeat;
    background-size: 14px auto;
}

ul.messagelist li.error {
    background: #ffefef url(../admin/img/icon-no.svg) 40px 12px no-repeat;
    background-size: 16px auto;
}

.errornote {
    font-size: 14px;
    font-weight: 700;
    display: block;
    padding: 10px 12px;
    margin: 0 0 10px 0;
    color: #ba2121;
    border: 1px solid #ba2121;
    border-radius: 4px;
    background-color: #fff;
    background-position: 5px 12px;
}

ul.errorlist {
    margin: 0 0 4px;
    padding: 0;
    color: #ba2121;
    background: #fff;
}

ul.errorlist li {
    font-size: 13px;
    display: block;
    margin-bottom: 4px;
}

ul.errorlist li:first-child {
    margin-top: 0;
}

ul.errorlist li a {
    color: inherit;
    text-decoration: underline;
}

td ul.errorlist {
    margin: 0;
    padding: 0;
}

td ul.errorlist li {
    margin: 0;
}

.form-row.errors {
    margin: 0;
    border: none;
    border-bottom: 1px solid #eee;
    background: none;
}

.form-row.errors ul.errorlist li {
    padding-left: 0;
}

.errors input, .errors select, .errors textarea {
    border: 1px solid #ba2121;
}

div.system-message {
    background: #ffc;
    margin: 10px;
    padding: 6px 8px;
    font-size: .8em;
}

div.system-message p.system-message-title {
    padding: 4px 5px 4px 25px;
    margin: 0;
    color: #c11;
    background: #ffefef url(../admin/img/icon-no.svg) 5px 5px no-repeat;
}

.description {
    font-size: 12px;
    padding: 5px 0 0 12px;
}

/* BREADCRUMBS */

div.breadcrumbs {
    background: #79aec8;
    padding: 10px 40px;
    border: none;
    font-size: 14px;
    color: #c4dce8;
    text-align: left;
}

div.breadcrumbs a {
    color: #fff;
}

div.breadcrumbs a:focus, div.breadcrumbs a:hover {
    color: #c4dce8;
}

/* ACTION ICONS */

.addlink {
    padding-left: 16px;
    background: url(../admin/img/icon-addlink.svg) 0 1px no-repeat;
}

.changelink, .inlinechangelink {
    padding-left: 16px;
    background: url(../admin/img/icon-changelink.svg) 0 1px no-repeat;
}

.deletelink {
    padding-left: 16px;
    background: url(../admin/img/icon-deletelink.svg) 0 1px no-repeat;
}

a.deletelink:link, a.deletelink:visited {
    color: #CC3434;
}

a.deletelink:focus, a.deletelink:hover {
    color: #993333;
    text-decoration: none;
}

/* OBJECT TOOLS */

.object-tools {
    font-size: 10px;
    font-weight: bold;
    padding-left: 0;
    float: right;
    position: relative;
    margin-top: -48px;
}

.form-row .object-tools {
    margin-top: 5px;
    margin-bottom: 5px;
    float: none;
    height: 2em;
    padding-left: 3.5em;
}

.object-tools li {
    display: block;
    float: left;
    margin-left: 5px;
    height: 16px;
}

.object-tools a {
    border-radius: 15px;
}

.object-tools a:link, .object-tools a:visited {
    display: block;
    float: left;
    padding: 3px 12px;
    background: #999;
    font-weight: 400;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #fff;
}

.object-tools a:focus, .object-tools a:hover {
    background-color: #417690;
}

.object-tools a:focus{
    text-decoration: none;
}

.object-tools a.viewsitelink, .object-tools a.golink,.object-tools a.addlink {
    background-repeat: no-repeat;
    background-position: 93% center;
    padding-right: 26px;
}

.object-tools a.viewsitelink, .object-tools a.golink {
    background-image: url(../admin/img/tooltag-arrowright.svg);
}

.object-tools a.addlink {
    background-image: url(../admin/img/tooltag-add.svg);
}

/* OBJECT HISTORY */

table#change-history {
    width: 100%;
}

table#change-history tbody th {
    width: 16em;
}

/* PAGE STRUCTURE */

#container {
    position: relative;
    width: 100%;
    min-width: 980px;
    padding: 0;
}

#content {
    padding: 20px 40px;
}

.dashboard #content {
    width: 600px;
}

#content-main {
    float: left;
    width: 100%;
}

#content-related {
    float: right;
    width: 260px;
    position: relative;
    margin-right: -300px;
}

#footer {
    clear: both;
    padding: 10px;
}

/* COLUMN TYPES */

.colMS {
    margin-right: 300px;
}

.colSM {
    margin-left: 300px;
}

.colSM #content-related {
    float: left;
    margin-right: 0;
    margin-left: -300px;
}

.colSM #content-main {
    float: right;
}

.popup .colM {
    width: auto;
}

/* HEADER */

#header {
    width: auto;
    height: 40px;
    padding: 10px 40px;
    background: #499088;
    line-height: 40px;
    color: #ffc;
    overflow: hidden;
}

#header a:link, #header a:visited {
    color: #fff;
}

#header a:focus , #header a:hover {
    text-decoration: underline;
}

#branding {
    float: left;
}

#branding h1 {
    padding: 0;
    margin: 0 20px 0 0;
    font-weight: 300;
    font-size: 24px;
    color: #f5dd5d;
}

#branding h1, #branding h1 a:link, #branding h1 a:visited {
    color: #f5dd5d;
}

#branding h2 {
    padding: 0 10px;
    font-size: 14px;
    margin: -8px 0 8px 0;
    font-weight: normal;
    color: #ffc;
}

#branding a:hover {
    text-decoration: none;
}

#user-tools {
    float: right;
    padding: 0;
    margin: 0 0 0 20px;
    font-weight: 300;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: right;
}

#user-tools a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

#user-tools a:focus, #user-tools a:hover {
    text-decoration: none;
    border-bottom-color: #79aec8;
    color: #85c1c8;
}

/* SIDEBAR */

#content-related {
    background: #f8f8f8;
}

#content-related .module {
    background: none;
}

#content-related h3 {
    font-size: 14px;
    color: #666;
    padding: 0 16px;
    margin: 0 0 16px;
}

#content-related h4 {
    font-size: 13px;
}

#content-related p {
    padding-left: 16px;
    padding-right: 16px;
}

#content-related .actionlist {
    padding: 0;
    margin: 16px;
}

#content-related .actionlist li {
    line-height: 1.2;
    margin-bottom: 10px;
    padding-left: 18px;
}

#content-related .module h2 {
    background: none;
    padding: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #eaeaea;
    font-size: 18px;
    color: #333;
}

.delete-confirmation form input[type="submit"] {
    background: #ba2121;
    border-radius: 4px;
    padding: 10px 15px;
    color: #fff;
}

.delete-confirmation form input[type="submit"]:active,
.delete-confirmation form input[type="submit"]:focus,
.delete-confirmation form input[type="submit"]:hover {
    background: #a41515;
}

.delete-confirmation form .cancel-link {
    display: inline-block;
    vertical-align: middle;
    height: 15px;
    line-height: 15px;
    background: #ddd;
    border-radius: 4px;
    padding: 10px 15px;
    color: #333;
    margin: 0 0 0 10px;
}

.delete-confirmation form .cancel-link:active,
.delete-confirmation form .cancel-link:focus,
.delete-confirmation form .cancel-link:hover {
    background: #ccc;
}

/* POPUP */
.popup #content {
    padding: 20px;
}

.popup #container {
    min-width: 0;
}

.popup #header {
    padding: 10px 20px;
}


.inline-group .vTextField {
    max-width: 100%;
    width: auto;
}
